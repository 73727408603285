import {logging} from 'protractor';
import {Title} from '@angular/platform-browser';
import {Component, OnInit} from '@angular/core';
import {fade, listAnimation} from '../animation';


@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.scss'],
  animations: [listAnimation]
})
export class ShowsComponent implements OnInit {

  locations: string[] = [];
  dates: string[] = [];
  upcominglocations: string[] = [];
  upcomingdates: string[] = [];
  shows: Show[] = [];
  upcomingShows: Show[] = [];
  today: Date = new Date();
  outPutString: String = '';


  constructor(private sideTitle: Title) {

    this.shows = [
      new Show(new Date('2025-03-04'), 'KRAMLADEN VIENNA'),
      new Show(new Date('2024-06-22'), 'HOFMUSIKFESTIVAL GREIN'),

      new Show(new Date('2024-05-21'), 'PFEILHEIM with Für Chiara'),
  
    new Show(new Date('2024-03-05'), 'KRAMLADEN VIENNA'),
    
    new Show(new Date('2023-12-01'), 'STADTWERKSTATT with Intra and Coperniquo'),
    new Show(new Date('2023-08-26'), 'BURNING STAGE FESTIVAL'),
    new Show(new Date('2023-08-05'), 'BRAIN BRIDGE'),    
    new Show(new Date('2023-05-11'), 'KRAMLADEN VIENNA'),new Show(new Date('2023-04-22'), 'BENEFIZKONZERT FUTURE4KIDS'),
      new Show(new Date('2022-12-09'), 'ZUCKERFABRIK ENNS with VERTILIZAR'),new Show(new Date('2022-08-12'), 'ROCK THE WINERY'),new Show(new Date('2022-08-12'), 'FREE TREE OPEN AIR'),new Show(new Date('2022-06-03'), 'LEONDING RATHAUSKELLER with TÄGLICH FRISCHES OBST'),new Show(new Date('2022-05-28'), 'ZIPF - ZIPFAIR MUSIC FESTIVAL'), new Show(new Date('2022-05-20'), 'BAUHOF PETTENBACH with CLEAREOL & DARKSTRAND'), new Show(new Date('2022-05-10'), 'KRAMLADEN VIENNA with PERFUMED GARDEN'), new Show(new Date('2021-11-12'), 'B72 VIENNA with THE ATTIC & PERFUMED GARDEN'), new Show(new Date('2021-10-01'), 'ANN&PAT LINZ with VERTILIZAR'),
      new Show(new Date('2021-09-18'), 'SZENE WIEN - 88.6 ROT WEISS ROCK STAGE'), new Show(new Date('2021-09-17'), 'HAID Hauptplatz - Ein Tag für Ansfelden'), new Show(new Date('2021-08-27'), 'SEEBÜHNE SEEHAM with SPEED LIMIT'),
      new Show(new Date('2021-07-31'), 'WOIDBRAND PUTZLEINSDORF'), new Show(new Date('2021-07-30'), 'AMSTETTEN - PFLASTERSOUND'),
      new Show(new Date('2021-06-25'), 'GARTENBÜHNE ANSFELDEN'), new Show(new Date('2021-06-09'), 'MUSIKPAVILLON LINZ'), new Show(new Date('2020-07-31'), 'MOSEREI SCHARNSTEIN'), new Show(new Date('2020-03-06'), 'STADTWERKSTATT LINZ - 15GB SUPPORT'), new Show(new Date('2020-02-07'), 'ANN&PAT LINZ with SEVENTEEN and SMOKING FROGS'), new Show(new Date('2020-01-11'), 'B72 VIENNA with RISK IT ALL FOR A…')
      , new Show(new Date('2019-11-29'), 'FORUM NEUHOFEN with SEVENTEEN and SMOKING FROGS'), new Show(new Date('2019-11-15'), 'BAUHOF PETTENBACH  - SCHEIBSTA & DIE BUBEN SUPPORT'),
      new Show(new Date('2019-10-12'), 'OUTBACK ROADHOUSE YBBS/DONAU'), new Show(new Date('2019-08-10'), 'STADTHALLE STEYREGG - STEEL THE NIGHT'), new Show(new Date('2019-06-01'),
        'ZIPF - ZIPFAIR MUSIC FESTIVAL CAMPING STAGE'), new Show(new Date('2019-05-30'), 'CAFETI CLUB AMSTETTEN - JAMSTETTEN'), new Show(new Date('2019-05-25'),
        'TROADKASTEN GALLNEUKIRCHEN'), new Show(new Date('2019-04-30'), 'HOFBÜHNE TEGERNBACH - JAM2NIGHT'), new Show(new Date('2019-03-22'),
        'ANN&PAT LINZ with SMALL HOURS and HIËNA'), new Show(new Date('2019-03-20'), 'KRAMLADEN VIENNA with GO WENT GONE'), new Show(new Date('2018-11-16'),
        'STADTWERKSTATT LINZ - THE HOWLING MUFFS SUPPORT'), new Show(new Date('2018-10-06'),
        'AERA VIENNA - JFBB SUPPORT'), new Show(new Date('2018-07-21'), 'BÖLLERBAUER HAAG - BLOOD MOON FESTIVAL'), new Show(new Date('2018-07-20'),
        'ROCKBAR AMSTETTEN - PFLASTERSOUND 5.0'), new Show(new Date('2018-06-09'), 'ZIPF - ZIPFAIR MUSIC FESTIVAL'), new Show(new Date('2018-04-28'),
        'BAUHOF PETTENBACH - KRAUTSCHÄDL SUPPORT'), new Show(new Date('2018-04-21'), 'SÄGEWERK OFFENHAUSEN - ROCK OH CITY'), new Show(new Date('2018-04-13'),
        'ESCAPE VIENNA - ROCK NIGHT OVER VIENNA'), new Show(new Date('2018-03-23'), 'K1 AMSTETTEN - CITYSOUND AMSTETTEN'), new Show(new Date('2018-02-17'),
        'VIPER ROOM VIENNA - FINALE SPH BANDCONTEST'), new Show(new Date('2018-02-08'), 'STADTWERKSTATT LINZ - DISCO 2000'), new Show(new Date('2017-12-01'),
        'ESCAPE VIENNA - PRE FINAL SPH BANDCONTEST'), new Show(new Date('2018-11-11'), 'AUERHAHN LINZ with LIQUID LUNCH'), new Show(new Date('2017-11-09'),
        'PALAIS LINZ - BORGWARTS'), new Show(new Date('2017-10-28'), 'DH5 LINZ - QLASH WOHNZIMMERSESSION'), new Show(new Date('2017-10-10'), 'RATHAUS LINZ'),
      new Show(new Date('2017-09-30'), 'ESCAPE VIENNA - REGIONALFINALE SPH BANDCONTEST'), new Show(new Date('2017-07-07'),
        'MUSIKPAVILLON LINZ with MINDBREAKFAST and ANOTHER KIND'), new Show(new Date('2017-06-23'), 'ZWETTL - MMA'), new Show(new Date('2017-06-10'),
        'RÖDA STEYR - STADTFINALE SPH BANDCONTEST'), new Show(new Date('2017-03-04'), 'RÖDA STEYR - VORRUNDE SPH BANDCONTEST'), new Show(new Date('2017-02-17'),
        'ANN&PAT LINZ - VERY FIRST TIME FESTIVAL')];

    this.upcomingShows = this.shows.map(x => Object.assign({}, x)).reverse();

    this.sideTitle.setTitle('One Last Glance - Shows');

  }

  ngOnInit(): void {
    this.today = new Date();

  }

  title: string = 'SHOWS';
  photo: string = './../../assets/git1b.jpg';
}


class Show {
  date: Date = new Date();
  location: String | undefined;
  name: String | undefined;

  constructor(date: Date, location: String) {
    this.date = date;
    this.location = location;

  }


}
