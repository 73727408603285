<div class="buttons">



    <div class="wrapper">

    </div>

    <div class="myButtons">
        <a
            href="https://labelboost.net/one-last-glance/surface">
            <button class="button"> SURFACE </button>
        </a>
        <a href="../about/">
            <button class="button"> ENTER HOMEPAGE</button>
        </a>
    </div>
</div>
